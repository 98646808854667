<template>
  <div class="admin-dashboard">
    <h1>Admin Dashboard</h1>
    <div v-if="!isAdmin">You do not have permission to view this page.</div>
    <div v-else-if="loading">Loading...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="sorting-controls">
        <label for="sort-select">Sort by: </label>
        <select id="sort-select" v-model="sortBy">
          <option value="alphabetical">Alphabetical</option>
          <option value="newest">Newest Account</option>
          <option value="recent">Most Recent Assessment</option>
        </select>
      </div>
      <div class="admin-dash-assessments">
        <div v-for="user in sortedUsers" :key="user.id" class="user-card">
          <h3 class="admin-name">{{ user.name }}</h3>
          <div class="admin-email"><a :href="'mailto:' + user.email">{{ user.email }}</a></div>
          <h5 class="admin-company">Company: <div class="admin-text">{{ user.company }}</div></h5>
          <h5 class="admin-industry">Industry:  <div class="admin-text">{{ user.industry }}</div></h5>
          <div class="admin-assessments">Assessments:</div>
          <ul class="admin-list">
            <li v-for="assessment in user.assessments" :key="assessment.id">
              {{ assessment.title }} <span class="admin-date">Started: {{ new Date(assessment.createdAt).toLocaleDateString() }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getValidToken } from '@/utils/authUtils';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AdminDashboard',
  data() {
    return {
      users: [],
      loading: true,
      error: null,
      sortBy: 'alphabetical'
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    isAdmin() {
      return this.user && this.user.isAdmin;
    },
    sortedUsers() {
      if (this.sortBy === 'alphabetical') {
        return [...this.users].sort((a, b) => (a.name || '').localeCompare(b.name || ''));
      } else if (this.sortBy === 'newest') {
        return [...this.users].sort((a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0));
      } else if (this.sortBy === 'recent') {
        return [...this.users].sort((a, b) => {
          const latestA = a.assessments && a.assessments.length ? Math.max(...a.assessments.map(assessment => new Date(assessment.createdAt || 0))) : 0;
          const latestB = b.assessments && b.assessments.length ? Math.max(...b.assessments.map(assessment => new Date(assessment.createdAt || 0))) : 0;
          return latestB - latestA;
        });
      }
      return this.users;
    }
  },
  methods: {
    ...mapActions(['logout']),
    async fetchAdminData() {
      try {
        const token = await getValidToken();
        if (!token) {
          console.error('No valid token found');
          return;
        }

        const apiUrl = process.env.VUE_APP_API_URL || 'https://storesafe.fyi';
        const response = await axios.get(`${apiUrl}/admin/users-with-assessments`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.users = response.data;
      } catch (err) {
        console.error('Error fetching admin data:', err);
        if (err.response && err.response.status === 401) {
          this.error = 'Your session has expired. Please refresh the page or log in again.';
        } else {
          this.error = 'An error occurred while fetching data. Please try again.';
        }
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.fetchAdminData();
  }
};
</script>

<style scoped>
.sorting-controls {
  margin-bottom: 20px;
}
select {
  padding: 5px;
  font-size: 16px;
}
</style>
<template>
  <div id="app" :class="currentRouteName">
    <div class="menu-container" :class="{ 'menu-open': isMenuOpen }">
      <button class="hamburger mobile" @click="toggleMenu">
        <span :class="{ 'active': isMenuOpen }"></span>
      </button>

      <nav>
      <router-link v-if="!isLoggedIn" to="/register" @click.native="closeMenu">Create an Account</router-link>
      <router-link v-if="!isLoggedIn" to="/login" @click.native="closeMenu">Log In</router-link>
      <span v-if="isLoggedIn">Welcome, {{ user.name }}!</span>
      <router-link v-if="isLoggedIn" to="/start-assessment" @click.native="closeMenu">New Assessment</router-link>
      <router-link v-if="isLoggedIn" to="/dashboard" @click.native="closeMenu">Dashboard</router-link>
      <router-link v-if="isLoggedIn" to="/login" @click.native="logout">Logout</router-link>
      <router-link v-if="isAdmin" to="/admin" @click.native="closeMenu">Admin Dashboard</router-link>
    </nav>
    </div>
    <router-view @login-success="handleLoginSuccess"/>
  </div>
</template>

<script>
import { getValidToken, clearAuthToken } from './utils/authUtils';
export default {
  name: 'App',
  data() {
    return {
      isMenuOpen: false,
      user: null
    };
  },
  computed: {
    isLoggedIn() {
      return !!this.user;
    },
    isAdmin() {
      return this.user && this.user.isAdmin;
    },
    currentRouteName() {
      return this.$route.name || '';
    }
  },
  methods: {
    async checkAuth() {
      const token = await getValidToken();
      if (!token) {
        this.user = null;
        localStorage.removeItem('user');
      } else {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          this.user = JSON.parse(storedUser);
        }
      }
    },
    handleLoginSuccess(user) {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      console.log('User after login:', this.user);
      console.log('Is Admin:', this.isAdmin);
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    logout() {
      clearAuthToken();
      localStorage.removeItem('user');
      this.user = null;
      this.$router.push('/login');
      this.isMenuOpen = false;
    }
  },
  created() {
    this.checkAuth();
  }
};
</script>
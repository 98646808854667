// src/utils/authUtils.js
import axios from 'axios';

export const getValidToken = async () => {
  const token = localStorage.getItem('token');
  console.log('Retrieved token from localStorage:', token ? 'exists' : 'not found');

  if (!token) return null;

  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    console.log('Decoded token:', decodedToken);

    if (decodedToken.exp * 1000 < Date.now()) {
      console.log('Token is expired, attempting to refresh');
      try {
        const response = await axios.post('/api/refresh-token', { token });
        const newToken = response.data.token;
        console.log('New token received:', newToken ? 'exists' : 'not received');
        localStorage.setItem('token', newToken);
        return newToken;
      } catch (error) {
        console.error('Error refreshing token:', error);
        localStorage.removeItem('token');
        return null;
      }
    } else {
      console.log('Token is still valid');
      return token;
    }
  } catch (error) {
    console.error('Error decoding token:', error);
    localStorage.removeItem('token');
    return null;
  }
};
export const setAuthToken = (token) => {
  localStorage.setItem('token', token);
};

export const clearAuthToken = () => {
  localStorage.removeItem('token');
};

export const isTokenValid = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  return decodedToken.exp * 1000 > Date.now();
};

export const logoutUser = () => {
  clearAuthToken();
};

export const loginUser = async (email, password) => {
  try {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    const data = await response.json();
    setAuthToken(data.token);
    return decodeToken(data.token);
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import html2pdf from 'html2pdf.js';
import WebFont from 'webfontloader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles.css';

WebFont.load({
  google: {
    families: ['Rajdhani:300,400,500,600,700']
  }
});

const app = createApp(App)

app.config.globalProperties.$html2pdf = html2pdf;

app.use(router)
app.use(store)
app.mount('#app')